




















































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import UIkit from 'uikit';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';

@Component({
  components: {
    ProgressButton
  }
})

export default class SessionPaymentChangeConfirmation extends Vue {

  screenText: ScreenText = new ScreenText();
  @PropSync('isVisible', { type: Boolean, required: true }) isVisibleSync!: boolean;
  @Prop({ type: String }) readonly sessionName!: string;

  @Watch('isVisible')
  onIsVisible(isVisible: boolean) {
    const isElementShowing = UIkit.modal(this.$el)
      ? UIkit.modal(this.$el).isToggled()
      : false;

    if (isVisible && isElementShowing === false) {
      UIkit.modal(this.$el).show();
    }
    else if (isVisible === false && isElementShowing) {
      UIkit.modal(this.$el).hide();
    }
  }

  get titleText(): string {
    return this.replaceSessionName(this.screenText.getScreenText('PAYMENT_SAVE_TITLE'), this.sessionName);
  }

  get descriptionTextLineOne(): string {
    return this.screenText.getScreenText('SAVE_DESC_LINE_ONE');
  }

  get descriptionTextLineTwo(): string {
    return this.screenText.getScreenText('SAVE_DESC_LINE_TWO');
  }

  get descriptionSaveConfirmText(): string {
    return this.screenText.getScreenText('SAVE_DESC_CONFIRMATION');
  }

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE_CAPS');
  }

  get cancelButtonText(): string {
    return this.screenText.getScreenText('BTN_CANCEL_CAPS');
  }

  primaryClick() {
    this.$emit('primaryClick');
  }

  closePopup() {
    this.isVisibleSync = false;
    this.$emit('closePopUp');
  }

  private replaceSessionName(screenText: string, name: string): string {
    return screenText.replace('[sessionName]', APP_UTILITIES.capitalize(name));
  }
}


